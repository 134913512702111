<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12" md="4">
          <h1 class="primary--text">DATOS</h1>
          <v-divider></v-divider>
          <v-text-field disabled
            filled
            hide-details="auto"
            v-model="pricing.clientes"
            label="Nombre Cliente/s"
            append-icon="mdi-account-multiple"
            :rules="[rules.req]"
          ></v-text-field>
          <v-text-field disabled label="Fecha Evento" clearable :value='pricing.fechaEv != null ? ((new Date(pricing.fechaEv).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(pricing.fechaEv).getDate()).slice(-2)) + (new Date(pricing.fechaEv).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(pricing.fechaEv).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense></v-text-field>
          <v-select
            disabled
            :items="espacios"
            :item-text="item => item.nombreEspacio"
            :item-value="item => item.idEspacio"
            filled
            hide-details="auto"
            v-model="pricing.idEspacio"
            label="Espacio"
            append-icon="mdi-home-group"
            clearable
          ></v-select>
          <v-text-field
            disabled
            filled
            hide-details="auto"
            v-model="pricing.personas"
            label="Personas"
            append-icon="mdi-human-male"
          ></v-text-field>
          <v-select
            disabled
            filled
            hide-details="auto"
            v-model="pricing.tipoPpto"
            label="Tipo Presupuesto"
            append-icon="mdi-file-sign"
            :items="tipoPpto"
            :item-text="item => item.title"
            :item-value="item => item.val"
          ></v-select>
          <v-text-field disabled label="Fecha Ppto" clearable :value='pricing.fecha != null ? ((new Date(pricing.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(pricing.fecha).getDate()).slice(-2)) + (new Date(pricing.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(pricing.fecha).getFullYear()) : ((new Date().toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date().getDate()).slice(-2)) + (new Date().toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date().getFullYear())' append-icon="mdi-calendar" readonly filled hide-details dense></v-text-field>
          <v-select
            disabled
            :items="cartas.filter(c => pricing.idEspacio ? c.idEspacio == pricing.idEspacio : true)"
            :item-text="item => (espacios.find(e => e.idEspacio == item.idEspacio)?.nombreEspacio || 'MISSING NAME') + ' ' + item.nombre + ' ' + item.year"
            :item-value="item => item.idCarta"
            filled
            hide-details="auto"
            v-model="pricing.idCarta"
            label="Carta base"
            append-icon="mdi-file-document"
            :rules="[rules.req]"
          >
          </v-select>
          <v-textarea
            disabled
            filled
            hide-details="auto"
            v-model="pricing.notas"
            label="Notas"
            append-icon="mdi-note-edit"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6">
              <h1 class="primary--text">PRICING (Carta: +{{ parseFloat(cartas.find(c => c.idCarta == pricing.idCarta)?.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €)</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposModifiacion">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                </div>
                <template v-if="pricing.modificaciones && pricing.modificaciones.length">
                  <template v-for="mod,i in pricing.modificaciones.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="9" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ modificaciones.find(r => r.idItem == mod.idItem)?.nombre }}</div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field disabled placeholder="UDS" @focus="$event.target.select()" dense class="v-superdense-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat( (tipo.val == 1) ? ( mod.add || 0 ) : ((tipo.val == 2) ? ( mod.remove || 0 ) : ((tipo.val == 3) ? ( mod.replace || 0 ) : 0))).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
                    </div>
                  </template>
                  <template v-for="mod,i in pricing.modificacionesCustom.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="9" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ mod.nombre }}</div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center">{{ mod.uds }}</v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat( mod.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-row>
                <v-col cols="12">
                  <v-text-field disabled style="align-self: baseline;" dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasModificaciones"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">TARIFAS</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center mt-0 mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Precio Pricing</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text" style="font-size:x-large;">{{ prTotal().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Precio Enviado</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text"><div style="font-size:x-large;width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field disabled :value="pricing.pDef" placeholder="Precio" @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" @input="changeDef($event)"></v-text-field> €</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1"><h3>Diferencia (€)</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat(pricing.pDef ? (pricing.pDef-prTotal()) : 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1" :style="'border-bottom: 2px solid ' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1"><h3>Diferencia (%)</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;"><v-text-field disabled :value="pricing.pntDif" placeholder="Porcentaje" @focus="$event.target.select()" dense class="v-superdense-input ended-input" hide-details="" @input="changeDif($event)"></v-text-field> %</div></v-col>
              </v-row>
              <v-row class="justify-space-between align-center mx-n1">
                <v-col cols="8" class="font-weight-bold primary--text py-0 ps-1" style="font-size:x-large;"><h3>Total desc/inc</h3></v-col>
                <v-col cols="4" class="font-weight-bold py-0 text-right primary--text" style="font-size:x-large;"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ (parseFloat(pricing.pDef ? (pricing.pDef-prTotal()) : 0) * (pricing.personas || 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field disabled dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-1"></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <h1 class="primary--text">CATERING & EXTRAS</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposCatering">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                </div>
                <template v-if="pricing.catering && pricing.catering.length">
                  <template v-for="mod,i in pricing.catering.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="9" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ mod.nombre }}</div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field disabled placeholder="UDS" @focus="$event.target.select()" dense class="v-superdense-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat( mod.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-text-field disabled dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasCatering"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">CATERING</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center">
                <v-col cols="8" class="font-weight-bold primary--text pb-0"><h3>Total Catering</h3></v-col>
                <v-col cols="4" class="font-weight-bold pb-0 text-right">{{ prCat().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field disabled dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResultCat"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="my-1"></v-divider>
          <v-row>
            <v-col cols="12" md="6">
              <h1 class="primary--text">ATENCIONES</h1>
              <v-divider class="mb-2"></v-divider>
              <template v-for="tipo in tiposAtenciones">
                <div class="d-flex flex-row">
                  <h3 style="max-width: min-content;">{{ tipo.nombre }}</h3>
                </div>
                <template v-if="pricing.catering && pricing.catering.length">
                  <template v-for="mod,i in pricing.catering.filter(r => r.tipoModificacion == tipo.val)">
                    <div style="display: flex; flex-direction: row;height: min-content !important;align-items: center;">
                      <v-col cols="9" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;color: var(--v-info-base)">{{ mod.nombre }}</div></v-col>
                      <v-col cols="1" style="height: min-content !important;" class="px-0 py-0 justify-center align-center"><v-text-field disabled placeholder="UDS" @focus="$event.target.select()" dense class="v-superdense-input" hide-details="" v-model="mod.uds" @input="$forceUpdate()"></v-text-field></v-col>
                      <v-col cols="2" style="height: min-content !important;" class="px-0 py-0"><div style="width: 100%; height: 100%; display: flex;align-items: center;justify-content: end;white-space: nowrap;">{{ parseFloat(mod.precio || 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</div></v-col>
                    </div>
                  </template>
                </template>
                <v-divider class="my-2"></v-divider>
              </template>
              <v-text-field disabled filled dense class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasAtenciones"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <h1 class="primary--text">ATENCIONES</h1>
              <v-divider class="mb-2"></v-divider>
              <v-row class="justify-space-between align-center">
                <v-col cols="8" class="font-weight-bold primary--text pb-0"><h3>Total Atenciones</h3></v-col>
                <v-col cols="4" class="font-weight-bold pb-0 text-right">{{ prAtenciones().toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €" }}</v-col>
              </v-row>
              <v-divider class="mt-3 mb-2"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field disabled dense filled class="v-superdense-input" placeholder="Notas" hide-details="" v-model="pricing.notasResultAtenciones"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <!-- <v-card-actions class="d-flex">
						<v-btn color="error" @click.stop="exportar">
							<v-icon left>mdi-file-pdf-box</v-icon>
                PDF
						</v-btn>
						<v-btn color="success" @click.stop="pricing = {}">
							<v-icon left>mdi-file-excel-box</v-icon>
                EXCEL
						</v-btn>
					</v-card-actions> -->
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { req, isNumber } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
    espacios: Array,
    cartas: Array,
    modificaciones: Array,
    tipo: Number,
    tipoPpto: Array
  },
  data() {
    return {
      revisiones:[],
      menu: false,
      menu2: false,
      search: "",
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tiposModifiacion: [
        { nombre: "Añadir", val : 1 },
        { nombre: "Quitar", val : 2 },
        { nombre: "Sustituir", val : 3 },
      ],
      tiposCatering: [
        { nombre: "Suplementos", val : 4 },
        { nombre: "Descuentos", val : 5 },
        { nombre: "Sustituir", val : 6 },
      ],
      tiposAtenciones: [
        { nombre: "Descuentos", val : 7 },
      ],
      default: {
        nombre : null,
      },
      pricing: {
        ...this.value,
      },
      rules: {
        req,
        isNumber,
      },
    };
  },
  methods: {
    // exportar(){
    //   var v = window.open('', '_blank','')
    //   v.document.write('<head><title>' + 'test' + '</title><style>ul {font-size: 14px !important};</style></head>');
    //   var l = v.document.createElement('link');
    //   l.rel = 'stylesheet';
    //   l.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900';
    //   //titulo
    //   var wrapper = v.document.createElement('div')
    //   wrapper.style = "display:block;-webkit-print-color-adjust:exact !important;print-color-adjust:exact !important;"
    //   var div = v.document.createElement('div')
    //   div.style = "display: flex; flex-wrap: wrap; width: 100%;align-items: flex-start;"


    //   var tabla = v.document.createElement('table')
    //   tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:100%"
    //   tabla.innerHTML = '<colgroup><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"><col width="5%"></colgroup>'
    //   var thead = v.document.createElement('thead')
    //   thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">DATOS</th></tr>'
    //   tabla.appendChild(thead)
    //   var tbody = v.document.createElement('tbody')
    //   tbody.innerHTML = "<tr><td colspan='6' style='border:1px solid black'><b>FECHA EV: </b>" + (this.pricing.fechaEv ? new Date(this.pricing.fechaEv).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : 'POR DEFINIR') + "</td><td colspan='8' style='border:1px solid black'><b>CLIENTES: </b>" + (this.pricing.clientes || '') + "</td><td colspan='6' style='border:1px solid black'><b>ESPACIO: </b>" + (this.espacios.find(e => e.idEspacio == this.pricing.idEspacio)?.nombreEspacio || 'POR DEFINIR') + "</td></tr>"
    //   tbody.innerHTML += "<tr><td colspan='5' style='border:1px solid black'><b>PERSONAS: </b>" + (this.pricing.personas || '') + "</td><td colspan='6' style='border:1px solid black'><b>TIPO PPTO: </b>" + (this.tipoPpto.find(p => p.val == this.pricing.tipoPpto)?.title || '') + "</td><td colspan='10' style='border:1px solid black'><b>CARTA BASE: </b>" + (this.pricing.idCarta ? this.cartas.find(c => c.idCarta == this.pricing.idCarta)?.nombre + this.espacios.find(e => e.idEspacio == this.cartas.find(c => c.idCarta == this.pricing.idCarta)?.idEspacio)?.nombreEspacio + this.cartas.find(c => c.idCarta == this.pricing.idCarta)?.year : 'POR DEFINIR') + "</td></tr>"
    //   tbody.innerHTML += "<tr><td colspan='5' style='border:1px solid black'><b>FECHA: </b>" + (this.pricing.fecha ? new Date(this.pricing.fecha).toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase() : new Date().toLocaleDateString('esp', { day: 'numeric', month : 'short', year: 'numeric' }).toUpperCase()) + "</td><td colspan='15' style='border:1px solid black'><b>NOTAS: </b>" + (this.pricing.notas || '') + "</td></tr>"
    //   tabla.appendChild(tbody)

    //   var thead = v.document.createElement('thead')
    //   thead.innerHTML = '<tr><th colspan="20" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
    //   var tbody = v.document.createElement('tbody')
    //   tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b>Precio enviado al cliente:</b> " + parseFloat(this.pricing.pDef ||this.prTotal()).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td style='border:1px solid black' colspan='10'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b>Dif pricing (€):</b>" + parseFloat(this.pricing.pDef ? (this.pricing.pDef-this.prTotal()) : 0).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</b></td></tr>"
    //   tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b>Precio pricing:</b> " + parseFloat(this.prTotal()).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td><td style='border:1px solid black' colspan='10'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b>Dif pricing (%):</b>" + parseFloat((this.pricing.pntDif || 0)).toFixed(2).replaceAll(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "%</b></td></tr>"
    //   tbody.innerHTML += "<tr><td colspan='10' style='border:1px solid black;'><div style='display:flex; justify-content:space-between;flex-wrap:nowrap'><b>Total descuento:</b> " + (parseFloat(this.pricing.pDef ? (this.pricing.pDef-this.prTotal()) : 0) * (this.pricing.personas || 0)).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €</div></td></tr>"
    //   tabla.appendChild(thead)
    //   tabla.appendChild(tbody)
    //   div.appendChild(tabla)

    //   var tabla = v.document.createElement('table')
    //   tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
    //   var thead = v.document.createElement('thead')
    //   var aux = this.pricing.modificaciones.sort((a,b) => a.tipoModificacion - b.tipoModificacion).filter(mod => [4,5,6].includes(mod.tipoModificacion))
    //   if (aux.length > 0){
    //     thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">CATERING</th></tr>'
    //     thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
    //     tabla.appendChild(thead)
    //     var tbody = v.document.createElement('tbody')
    //     aux.forEach(mod => {
    //       tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + ["ADD", "REM", "RPL"][mod.tipoModificacion-4] + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + ([parseFloat(mod.add || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.remove || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.replace || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")][mod.tipoModificacion-4]) + "€</td></tr>"
    //     })
    //     tabla.appendChild(tbody)
    //   }
    //   div.appendChild(tabla)

    //   var tabla = v.document.createElement('table')
    //   tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
    //   var thead = v.document.createElement('thead')
    //   var aux = this.pricing.modificaciones.sort((a,b) => a.tipoModificacion - b.tipoModificacion).filter(mod => [7].includes(mod.tipoModificacion))
    //   if (aux.length > 0){
    //     thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">ATENCIONES</th></tr>'
    //     thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
    //     tabla.appendChild(thead)
    //     var tbody = v.document.createElement('tbody')
    //     aux.forEach(mod => {
    //       tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + (parseFloat(mod.remove || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")) + "€</td></tr>"
    //     })
    //     tabla.appendChild(tbody)
    //   }
    //   div.appendChild(tabla)

    //   var tabla = v.document.createElement('table')
    //   tabla.style = "border-collapse: collapse;font-family: \'Roboto\', sans-serif;width:calc(100%/3)"
    //   var thead = v.document.createElement('thead')
    //   var aux = this.pricing.modificaciones.sort((a,b) => a.tipoModificacion - b.tipoModificacion).filter(mod => [1,2,3].includes(mod.tipoModificacion))
    //   if (aux.length > 0){
    //     thead.innerHTML = '<tr><th colspan="3" style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRICING</th></tr>'
    //     thead.innerHTML += '<tr><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">ITEM</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">UDS</th><th style="padding: 3px;background-color: lightgray ; border:1px solid gray">PRECIO</th></tr>'
    //     tabla.appendChild(thead)
    //     var tbody = v.document.createElement('tbody')
    //     aux.forEach(mod => {
    //       tbody.innerHTML += "<tr style='border: 1px solid black'><td>" + ["ADD", "REM", "RPL"][mod.tipoModificacion-1] + (this.modificaciones.find(m => m.idItem == mod.idItem)?.nombre) + "</td><td style='text-align:center'>" + (mod.uds || 1) + "</td><td style='text-align:right'>" + ([parseFloat(mod.add || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.remove || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, "."), parseFloat(mod.replace || 0).toFixed(2).replaceAll('.',',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")][mod.tipoModificacion-1]) + "€</td></tr>"
    //     })
    //     tabla.appendChild(tbody)
    //   }
    //   div.appendChild(tabla)

    //   wrapper.appendChild(div)

    //   v.document.write(wrapper.outerHTML)
    //   v.print()

    // },
    prTotal(){
      var tot = (this.pricing.precioCarta || 0)
      if (!this.pricing.modificaciones) this.pricing.modificaciones = []
      this.pricing.modificaciones.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = (mod.add ||0)
        }
        else if (mod.tipoModificacion==2){
          add = (mod.remove ||0)
        }
        else if (mod.tipoModificacion==3){
          add = (mod.replace ||0)
        }
        tot += (mod.uds * add)
      })
      if (!this.pricing.modificacionesCustom) this.pricing.modificacionesCustom = []
      this.pricing.modificacionesCustom.forEach(mod => {
        var add = 0
        if (mod.tipoModificacion==1){
          add = mod.precio
        }
        else if (mod.tipoModificacion==2){
          add = Math.abs(mod.precio)*-1
        }
        else if (mod.tipoModificacion==3){
          add = mod.precio
        }
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prCat(){
      var tot = 0
      if (!this.pricing.catering) this.pricing.catering = []
      this.pricing.catering.filter(m => [4,5,6].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
    prAtenciones(){
      var tot = 0
      if (!this.pricing.catering) this.pricing.catering = []
      this.pricing.catering.filter(m => [7].includes(m.tipoModificacion)).forEach(mod => {
        var add = mod.precio
        tot += (mod.uds * add)
      })
      return parseFloat(tot || 0)
    },
  }
};
</script>


<style scoped>
.ended-input >>> input {
    color: var(--v-info-base);
    text-align:right;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
</style>