import { render, staticRenderFns } from "./RevisionPricing.vue?vue&type=template&id=5c52bf24&scoped=true"
import script from "./RevisionPricing.vue?vue&type=script&lang=js"
export * from "./RevisionPricing.vue?vue&type=script&lang=js"
import style0 from "./RevisionPricing.vue?vue&type=style&index=0&id=5c52bf24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c52bf24",
  null
  
)

export default component.exports